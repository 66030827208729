import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { subpageContent, sliderCompanies, testimonials } from "../utils/products/subpageInformation";

import ImageCarousel from "../components/image-carousel/image-carousel";
import Comparison from "../components/comparison/comparison";
import InfoImage from "../components/info-image/info-image";
import Banner from "../components/banner/banner";
import { bannerCMS } from "../utils/generalInformation";




// markup
const AdditionalProd = () => {


    return (
        <Layout
            SEOTitle={"Additional Products - United Legal Assistance"}
            SEODescription={"We are aware of the need for our commercial partners to cater for a wider variety of clients and as such, we also provide the following range of policies: - Family Legal Expenses - Commercial Legal Expenses - Landlord Legal Expenses & Rent Guarantee - Home Emergency (Residential & Let) - Motor Breakdown Recovery - Motor Excess Protection"}
            footerDisclaimer={""}>

            <InfoImage props={subpageContent} origin="additional" />

            <Banner props={bannerCMS} />

            <ImageCarousel props={sliderCompanies} />

            <ImageCarousel props={testimonials} />

        </Layout>
    )

}

export default AdditionalProd